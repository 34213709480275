/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : MediaQuery 768
    Author     : Rizki Nida Chaerulsyah - akuiki.net
==============================================================================================*/


/* structure
----------------------------------------------------------------------------------------------*/

.grid-row-auto {
    grid-auto-rows: max-content;
}

.grid-row-1 {
    grid-row: 1;
}

.grid-row-2 {
    grid-row: 2;
}

.grid-row-3 {
    grid-row: 3;
}

.grid-row-4 {
    grid-row: 4;
}

.grid-row-5 {
    grid-row: 5;
}

.grid-row-6 {
    grid-row: 6;
}

.grid-row-7 {
    grid-row: 7;
}

.grid-row-8 {
    grid-row: 8;
}

.grid-row-9 {
    grid-row: 9;
}

.grid-row-10 {
    grid-row: 10;
}

.grid-row-11 {
    grid-row: 11;
}

.grid-row-12 {
    grid-row: 12;
}

.grid-row-13 {
    grid-row: 13;
}

.grid-row-14 {
    grid-row: 14;
}

.grid-row-15 {
    grid-row: 15;
}

.grid-row-16 {
    grid-row: 16;
}


/* header
----------------------------------------------------------------------------------------------*/

.menu-content .logo {
    right: 28px;
}

header .left.search,
header .left.lang {
    display: none;
}

.menu-content .left {
    width: 54%;
}

.menu-content .right {
    width: 46%;
}

.menu-content .right .wrapper {
    margin: 95px 0 0 28px;
}

.menu-content .grid-view .box {
    width: 100%;
}

.menu-content .grid-view .box:nth-child(2) {
    display: none;
}

.floatingmenu {
    width: 88.7%;
}

.floatingmenu .row input[type='submit'] {
    min-width: 125px;
    font-size: 13px;
    margin-top: 5px;
}

.floatingmenu .row input[type='text'],
.floatingmenu .row input[type='file'],
.floatingmenu .row select {
    font-size: 13px;
}


/* middle
----------------------------------------------------------------------------------------------*/

.grid-view .box {
    width: 50%;
}

.grid-view .left,
.grid-view .box.slider-wrap {
    width: 100%;
}

.grid-view .box .text.perencanaan h6 {
    font-size: 22px;
    margin: 0 0 10px 0;
}

.grid-view .box--column.news .text {
    bottom: 80px;
}

.grid-view .box--grid .text-money h2 {
    font-size: 60px;
}

.grid-view .box--grid .box--column.callus .text:after {
    display: none;
}

.grid-view .box--grid .box--column.callus:hover .text img {
    opacity: 1;
}

.grid-view .box--grid .box--column.fund.testi .pager-info {
    bottom: 33.5%;
}

.content .banner {
    height: 365px;
}

.content .title-page {
    max-width: 100%;
}

.content .title-page .ico {
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.inner-content {
    width: 100%;
}

.inner-content h2 {
    font-size: 40px;
    line-height: 45px;
}

.inner-content h4 {
    font-size: 20px;
    line-height: 24px;
}

.wrap-popup .box-popup.about p,
.wrap-popup .box-popup.about ul li,
.inner-content table,
.inner-content ul li,
.inner-content ol li,
.inner-content p {
    font-size: 16px;
    line-height: 24px;
}

.inner-content p {
    margin: 0 0 15px 0;
}

.inner-content blockquote,
.inner-content p.quote {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 25px 0;
}

.inner-content .image.offset {
    margin: 40px 0 40px -80px;
}

.inner-content .list-direksi .box-title,
.inner-content .list-direksi .box {
    width: 262px;
    min-height: 321px;
}

.inner-content .list-direksi .box .box-blue,
.inner-content .list-direksi .box .image {
    height: 321px;
}

.inner-content .list-direksi .box-title h2 {
    font-size: 23px;
    line-height: 28px;
    margin: 70px 34px 0;
}

.inner-content .list-direksi .box .text h3 {
    font-size: 23px;
    line-height: 20px;
    margin: 0 0 3px;
}

.inner-content .list-direksi .box .text {
    top: 245px;
}

.inner-content .list-direksi .box .text i {
    font-size: 14px;
}

.inner-content h5 {
    margin: 0 0 20px 0;
    font-size: 22px;
}

.list-rekanan .box {
    padding: 15px 0;
}

.list-rekanan .box h3 {
    font-size: 20px;
    height: 35px;
    line-height: 35px;
    padding: 0 0 0 20px;
}

.inner-content .wrap-tab .nav-tab {
    margin: 0px 0 35px;
}

.inner-content .wrap-tab .nav-tab a {
    height: 50px;
    line-height: 53px;
    width: auto;
    padding: 0 15px;
    font-size: 14px
}

.list-box .box {
    width: 208px;
    height: 208px;
}

.list-box .box:nth-child(3n) {
    margin-right: 2px;
}

.list-box .box .text {
    padding: 55px 20px 0;
    position: relative;
}

.list-box .box p {
    font-size: 14px;
}

.list-box .box .label-ico {
    width: 32px;
    height: 32px;
    top: 10px;
    right: 10px;
    background-size: 100% auto !important;
}

.list-box .box h6 {
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    font-weight: normal;
}

.list-box .box a {
    left: 20px;
    bottom: 20px;
    font-size: 12px;
}

.list-box .box {
    width: 261px;
    height: 261px;
}

.inner-content h6 {
    font-size: 18px;
}

.inner-content ul.col-3 li {
    width: 225px;
    margin-bottom: 5px
}

.inner-content ul.col-3 li:nth-child(3n) {
    margin-right: 40px;
}

.inner-content ul.col-3 li:nth-child(2n) {
    margin-right: 0px;
}

.inner-content ul li,
.inner-content ol li {
    background-position: left 9px !important
}

.inner-content .grid-view .box {
    width: 210px;
}

.inner-content .grid-view .box.brosur .text p {
    margin: 0 0 8px;
    font-size: 12px;
    height: auto;
    overflow: hidden;
}

.inner-content .grid-view .box.brosur h3 {
    font-size: 18px;
    margin-bottom: 30px;
}

.inner-content .grid-view .box .text {
    padding: 20px;
}

.inner-content .grid-view .box--grid .box--column.callus h3 {
    font-size: 15px;
}

.inner-content .grid-view .box--grid .box--column.callus .text {
    bottom: 0;
    padding-bottom: 26%;
}

.inner-content .grid-view .box--grid .box--column.callus .text a {
    bottom: 20px;
}

.inner-content .grid-view .box--grid .box--column.callus .text:after {
    top: 17px;
}

.inner-content .grid-view .box--grid .box--column.callus img {
    margin-bottom: -10px;
}

git .grid-view .left.dropdown,
.grid-view .left.search {
    width: auto;
}

.bar-filter .search input,
.bar-filter .dropdown .cover-combo,
.bar-filter .dropdown select,
.bar-filter .dropdown .inner-combo,
.bar-filter {
    height: 60px;
    line-height: 60px;
}

.bar-filter .search input,
.bar-filter .dropdown .inner-combo {
    font-size: 14px;
}

.bar-filter .dropdown select {
    width: 245px
}

.bar-filter .search input {
    width: 180px
}

.bar-filter .dropdown.right select {
    width: 195px
}

.list-rekanan .box h3 {
    font-size: 20px;
    height: auto;
    line-height: 24px;
}

.filter-tahun input[type=text] {
    width: 118px;
    font-size: 14px;
    padding: 0 30px 0 10px;
    background-position: 135px center;
}

.filter-tahun input[type=submit] {
    width: 120px;
}

.price td,
.price thead th {
    padding: 9px 20px;
    font-size: 14px
}

.inner-content .grid-view.career .box .text {
    padding: 15px;
}

.inner-content .grid-view.career .text p {
    font-size: 12px;
    margin: 5px 0 30px 0;
}

.inner-content .grid-view.career .text h3 {
    font-size: 20px
}

.inner-content .grid-view.career .text img {
    height: 45px;
    margin: 0 auto 10px;
}

.inner-content .grid-view.career .text a {
    margin-right: 0;
    overflow: hidden;
    line-height: 15px;
    padding: 0 20px 0 0;
    display: inline-block;
    width: 110px;
}

.inner-content.career .top_src label {
    display: block;
    font-size: 14px;
    margin: 0 0 4px;
}

.inner-content.career .top_src select {
    width: 178px;
}

.inner-content.career .top_src .cover-combo {
    margin: 0
}

.inner-content.career .top_src .col:first-child {
    margin-right: 10px;
}

.inner-content.career .top_src .col:last-child {
    margin-right: 0
}

.inner-content.career .top_src .cover-combo .inner-combo {
    font-size: 14px;
    padding: 13px 10px;
    color: #999;
    padding-right: 30px;
}

.inner-content.career .top_src input[type="submit"] {
    width: 154px;
    font-size: 14px;
    margin-top: 19px;
}

.wrap-popup .box-popup.about {
    padding: 60px 45px;
    width: 430px;
}

.wrap-popup .box-popup.about h2 {
    font-size: 30px;
    line-height: 35px;
}

.wrap-popup .box-popup.about h6 {
    font-size: 16px;
}

.wrap-popup .box-popup.about ul li,
.wrap-popup .box-popup.about p {
    font-size: 14px;
}

.wrap-popup .box-popup.about ul li {
    background-position: left 9px !important;
}

.inner-content.career.apply h4 {
    margin-top: -10px;
}

.input_file {
    background-size: 15px auto !important;
}

.input_file,
.form_apply .row .col input[type="text"] {
    width: 235px;
    padding: 10px;
    font-size: 14px;
}

.form_apply .row .col.full input[type="text"] {
    width: 508px;
}

.form_apply .row .col label {
    font-size: 16px;
}

.inner-content .form_contact .row:first-child select {
    width: 85px;
    height: 38px;
}

.inner-content .form_contact .row .cover-combo .inner-combo {
    font-size: 14px;
    padding: 12px;
}

.inner-content .form_contact .row .cover-combo {
    height: 38px;
}

.inner-content .form_contact .row:first-child input[type="text"] {
    width: 398px;
}

.inner-content .form_contact .row:nth-child(4) select {
    width: 520px
}

.inner-content .form_contact .row input[type="text"],
.inner-content .form_contact .row textarea {
    font-size: 14px;
    padding: 11px;
}

.inner-content .form_contact .row input[type="text"] {
    width: 229px;
}

.inner-content .form_contact .row:nth-child(2) input[type="text"] {
    margin-right: 10px;
}

.inner-content .form_contact .row .cover-combo {
    margin-right: 10px;
}

.inner-content .form_contact .row label {
    font-size: 16px;
    margin-bottom: 9px;
}

.inner-content .form_contact .row:nth-child(3) input[type="text"] {
    width: 388px;
}

.inner-content .form_contact .row .div_check span {
    font-size: 14px;
}

.inner-content .form_contact .row select {
    height: 38px;
}

.inner-content .form_contact .row textarea {
    width: 490px;
    height: 120px;
}

.content .title-page .nav {
    width: 60px;
    top: 60px;
}

.content .title-page .nav a {
    width: 60px;
    height: 60px;
    background-size: 120px auto !important;
}

.inner-content .box-testi .image {
    width: 80px;
}

.inner-content .box-testi .text {
    width: 420px;
}

.inner-content .box-testi .text h5 {
    font-size: 24px;
}

.inner-content .box-testi .text h6 {
    font-size: 14px;
    margin: 0 0 30px 0;
}

.inner-content .box-testi .text p.quote {
    font-size: 20px;
    line-height: 24px;
}

.filter-tahun input[type=submit],
.inner-content.career .top_src input[type="submit"] {
    font-size: 14px;
}


/* footer
----------------------------------------------------------------------------------------------*/

footer {
    padding: 40px 20px 80px 20px;
}

@media only screen and (min-width: 767px) {
    /* general */
    .mw-50 {
        max-width: 50% !important;
    }
    .mw-70 {
        max-width: 70% !important;
    }
    .grid-view .box--grid .text-money h5 {
        margin: 20px 0 20px 0;
    }
}

@media only screen and (max-width:767px) {
    footer .grid {
        grid-template-columns: 2fr 2fr;
        grid-template-rows: auto auto 0px 10px 5px;
        grid-gap: 60px 10px;
        grid-auto-flow: row;
    }
    .floatingmenu {
        width: 100%;
    }
    .content .content-wrapper .inner-content {
        padding-right: 40px;
    }
    aside nav a.toggle-menu {
        width: 110px;
        height: 110px;
    }
    .btn-readmore {
        padding: 0 15px;
        line-height: 30px;
        font-size: 14px;
    }
    .tittle-slider h3 {
        float: none;
        display: block;
    }
    .tittle-slider .btn-readmore {
        float: none;
        margin-top: 15px;
    }
    .inner-content.product-detail .grid-view .box {
        min-height: 0;
        max-height: 250px;
    }
    .box-slideristimewa .list-slider .col {
        padding: 40px 30px;
    }
    .box-slidermanfaat .list-slider .col {
        padding: 40px 30px;
    }
    .grid-view .box--grid .box--column .box--grid-wrapper .text {
        padding-bottom: 20px;
    }
    .grid-view .box--grid .box--column.callus img {
        width: 60px;
    }
    .grid-view .left,
    .grid-view .box--grid .box--column.fund {
        padding-bottom: 30px;
    }
    .grid-view .box--grid .box--column.callus,
    .grid-view .box--grid .box--column.customer.connect {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .grid-view .box--grid .social-embed {
        padding: 40px 20px;
    }
    .grid-view .box--grid .text-money h5 {
        margin: 40px 0px;
    }
    .grid-view .box--grid .text-money {
        padding: 30px 30px 70px;
    }
    .grid-view .box--grid .box--column.fund.testimonial .bx-wrapper .bx-controls-direction {
        bottom: 55px;
        position: absolute;
    }
    .grid-view .box--grid .pager-info {
        bottom: 25%;
        margin-left: -25px;
    }
}

@media only screen and (min-width:767px) and (max-width:960px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 320px !important;
    }
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 310px !important;
    }
    .all-content .sidebar-right {
        bottom: 8vh;
    }
}